













import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';

import { ListingCategory } from 'client-website-ts-library/types';

import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
  },
})
export default class PropertiesForRent extends Mixins(View) {
  private listingFilter: ListingFilter | null = null;

  handleFilter(filter: ListingFilter) {
    console.log(filter);
    // eslint-disable-next-line no-param-reassign
    filter.OrderByStatements = [ListingOrderBy.CreateDateDesc];
    // eslint-disable-next-line no-param-reassign
    filter.Categories = [ListingCategory.ResidentialRental, ListingCategory.HolidayRental, ListingCategory.Commercial];
    this.listingFilter = filter;
  }
}
